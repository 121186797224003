<template>
<!--    @click="$modal.hide('Welcome')"   - use this for close modal window-->
    <div class="wellcome-msg">
        <p class="wellcome-msg__name">Уважаемый {{name}}!</p>
        <p class="wellcome-msg__info">Добро пожаловать в личный кабинет.<br>
            {{ this.status==="phone" ? 'Ваш телефон подтвержден.'
            : this.status==="email" ? 'Ваша почта была успешо подтверждена.'
            : "" }}
        </p>
        <p class="wellcome-msg__action">
            {{
            this.status==="phone" ?
            'На указанную Вами почту отправлено письмо со ссылкой на подтверждение регистрации.'
            : this.status==="email" ? 'Для завершения процесса регистрации заполните, пожалуйста, недостающие данные'
            : "Вы успешно завершили регистрацию, как только администратор проверит Ваши данные, у Вас появится полный доступ к личному кабинету"
            }}
        </p>
        <rwm-button width="100%" @click="onClick()">Продолжить</rwm-button>
    </div>
</template>

<script>
    import RwmButton from "../RWMButton/RwmButton";
    export default {
        name: "Welcome",
        components: {RwmButton},
        props: {
            name: {
                type: String,
                default: ""
            },
            to: String,
            status: {
                type: String,
                default: "phone",
                validator: function(value){
                    return value==='phone' || value==='email' || value==='completed';
                }
            }
        },
        methods: {
            onClick() {
                if(this.status==='phone') this.$emit('logout');
                if(this.status === 'email') {
                    this.$emit('click')
                    return
                }
                this.$router.push(this.to)
            }
        }
    }
</script>

<style scoped lang="scss">
    .wellcome-msg {
        max-width: 600px;
        margin: auto;
        color: $RWM-grey;
        &__info,
        &__action{
            font-weight: 500;
            font-size: 23px;
            line-height: 31px;
            margin-bottom: 44px;
        }
        &__name {
            font-weight: 600;
            font-size: 23px;
            line-height: 31px;
            margin-bottom: 44px;
        }
    }
</style>